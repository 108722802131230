import React from 'react';

import Layout from "../components/Layout";

const NotFoundPage = () => (
    <Layout>
        <section>
            <h1>404: NOT FOUND</h1>
        </section>
    </Layout>
);

export default NotFoundPage;